import React from 'react';
import * as Sentry from '@sentry/node';
import { RewriteFrames } from '@sentry/integrations';
import getConfig from 'next/config';
import '@sensei/pi-component/dist/theme/browser-normalization.css';
import ThemeProvider from '../src/context/ThemeProvider';
import { ErrorBoundary } from '../src/component/errorBoundary/ErrorBoundary';
import '../style/app.css';
import { CssBaseline } from '@mui/material';

if (process.env.NEXT_PUBLIC_SENTRY_DSN) {
    // https://github.com/vercel/next.js/tree/canary/examples/with-sentry
    const config = getConfig();
    const distDir = `${config?.serverRuntimeConfig?.rootDir}/.next`;
    Sentry.init({
        enabled: process.env.NODE_ENV === 'production',
        integrations: [
            new RewriteFrames({
                iteratee: (frame) => {
                    frame.filename = frame?.filename?.replace(
                        distDir,
                        'app:///_next',
                    );
                    return frame;
                },
            }),
        ],
        dsn: process.env.NEXT_PUBLIC_SENTRY_DSN,
        environment: process.env.SENTRY_ENV || 'development',
    });
}

export default function App({
    Component,
    pageProps,
}: {
    Component: React.FC<any>;
    pageProps: object;
}) {
    React.useEffect(() => {
        // Remove the server-side injected CSS.
        const jssStyles = document.querySelector('#jss-server-side');
        if (jssStyles) {
            jssStyles?.parentElement?.removeChild(jssStyles);
        }
    }, []);

    return (
        <ErrorBoundary>
            <ThemeProvider>
                <CssBaseline />
                <Component {...pageProps} />
            </ThemeProvider>
        </ErrorBoundary>
    );
}
