import { ThemeOptions } from '@mui/material';
import {
    minion,
    minionIt,
    minionSemiBold,
    theinhardtLight,
    theinhardtLightItalic,
    theinhardtMedium,
    theinhardtRegular,
} from '../fonts';
import { breakpoints } from '@sensei/pi-component/dist/theme/breakpoints';

const generateFontFaces = () => {
    return [
        minionIt,
        minionSemiBold,
        theinhardtLight,
        theinhardtLightItalic,
        theinhardtRegular,
        theinhardtMedium,
    ].map((fontFace) => {
        return {
            '@font-face': {
                fontFamily: fontFace.fontFamily,
                fontStyle: fontFace.fontStyle,
                fontStretch: fontFace.fontStretch,
                fontWeight: fontFace.fontWeight,
                src: fontFace.src,
            },
        };
    });
};

export const MuiCssBaseline = (theme: ThemeOptions) => {
    const styles = theme.components?.MuiCssBaseline;
    if (styles && typeof styles.styleOverrides === 'object') {
        styles.styleOverrides = {
            ...styles.styleOverrides,

            // adds one font-face
            '@font-face': {
                fontFamily: minion.fontFamily,
                fontStyle: minion.fontStyle,
                fontStretch: minion.fontStretch,
                fontWeight: minion.fontWeight,
                src: minion.src,
            },

            // adds the rest of the font faces
            fallbacks: generateFontFaces(),

            'em, em.MuiTypography-root, .MuiTypography-root em': {
                fontFamily: [
                    minion.fontFamily,
                    'Times New Roman',
                    'serif',
                ].join(', '),
                fontStyle: 'normal',
            },
            'i, i.MuiTypography-root, .MuiTypography-root i': {
                fontStyle: 'italic',
            },
            strong: {
                fontWeight: 500,
            },
            b: {
                letterSpacing: '0.05em',
                fontWeight: 'inherit',
            },
            body: {
                lineHeight: 'normal',
                letterSpacing: 'normal',
                fontSize: '14px',
                [breakpoints.up('lg')]: {
                    lineHeight: 'normal',
                    letterSpacing: 'normal',
                    fontSize: '16px',
                },
            },
        };
    }

    return styles;
};
