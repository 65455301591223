import * as React from 'react';
import { ThemeProvider as MuiThemeProvider } from '@mui/material/styles';

import theme from '../component/App/theme';

function ThemeProvider({ children }: React.PropsWithChildren<{}>) {
    return (
        <MuiThemeProvider theme={theme}>
            <>{children}</>
        </MuiThemeProvider>
    );
}

export default ThemeProvider;
