import { colors } from '../colors';
import { ThemeOptions } from '@mui/material/styles';

export const PaletteTheme = (
    theme: ThemeOptions,
    themeType: 'dark' | 'light',
) => {
    return {
        ...theme.palette,
        ...{
            primary: {
                light: colors.CARBON,
                dark: colors.WHITE,
                main: themeType === 'light' ? colors.CARBON : colors.WHITE,
            },
            secondary: {
                light: colors.WHITE,
                dark: colors.CARBON,
                main: themeType === 'light' ? colors.WHITE : colors.CARBON,
            },
            text: {
                primary: colors.CARBON,
                secondary: colors.MEDIUM_GREY,
            },
        },
    };
};
