import { ThemeOptions } from '@mui/material';
import { breakpoints } from '@sensei/pi-component/dist/theme/breakpoints';

export const MuiFormLabel = (theme: ThemeOptions) => {
    return {
        styleOverrides: {
            root: {
                lineHeight: 'normal',
                [breakpoints.up('lg')]: {
                    lineHeight: 1,
                    letterSpacing: 'normal',
                },
            },
        },
    };
};
