import { ThemeOptions } from '@mui/material';
import { breakpoints } from '@sensei/pi-component/dist/theme/breakpoints';
import { spacingFunc } from '@sensei/pi-component/dist/theme/spacing';

export const MuiInputBase = (theme: ThemeOptions) => {
    return {
        styleOverrides: {
            root: {
                [breakpoints.up('lg')]: {
                    lineHeight: `${spacingFunc(2.715)}px`,
                    letterSpacing: 'normal',
                },
            },
        },
    };
};
