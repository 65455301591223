import { ThemeOptions } from '@mui/material';
import {
    fontSize,
    typography,
} from '@sensei/pi-component/dist/theme/typography';
import { breakpoints } from '@sensei/pi-component/dist/theme/breakpoints';
import { spacingFunc } from '@sensei/pi-component/dist/theme/spacing';

export const MuiButton = (theme: ThemeOptions) => {
    let rootStyles = {};
    if (theme.components?.MuiButton?.styleOverrides?.root) {
        rootStyles = theme.components?.MuiButton?.styleOverrides?.root;
    }

    return {
        styleOverrides: {
            root: {
                ...rootStyles,
                '&[direction="backward"]': {
                    paddingLeft: 0,
                    paddingRight: 0,
                    '& .MuiButton-endIcon': {
                        margin: 0,
                    },
                },
                borderRadius: `calc(${typography.pxToRem(fontSize)} + 8px)`,
                fontWeight: 300,
                letterSpacing: 'normal',
                [breakpoints.up('lg')]: {
                    fontSize: '1rem',
                },
            },
            containedSizeLarge: {
                borderRadius: `calc(${typography.pxToRem(fontSize)} + 8px)`,
            },
            outlinedSizeLarge: {
                borderRadius: `calc(${typography.pxToRem(fontSize)} + 8px)`,
            },
            iconSizeMedium: {
                '& > *:first-of-type': {
                    fontSize: '1em',
                },
            },
            text: {
                padding: spacingFunc(0.75, 1),
            },
        },
    };
};
