import { colors as defaultColors } from '@sensei/pi-component/dist/theme/colors';

export const colors = {
    ...defaultColors,
    GOLD: '#CFBE8E',
    SAND_STONE: '#F8F8F5',
    SAND_STONE_LIGHT: '#FEFEFC',
    GREY: '#888888',
    GREY_SH_1: '#EBEBE9',
    MACY_GREY: '#D9D9D9',
    GRAPHITE: '#535353',
    CARBON: '#282828',
    MEADOW: '#93a094',
    DARK_MEADOW: '#5d645e',
    BLACK: '#000000',
    RED: '#ff4646',
};
