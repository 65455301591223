import {
    darkThemeOptions as piDarkThemeOptions,
    lightThemeOptions as piLightThemeOptions,
    PiLetterCircleThemeOptions,
    PiTwoPaneLayoutThemeOptions,
} from '@sensei/pi-component';
import { createTheme, ThemeOptions } from '@mui/material/styles';
import { MuiCssBaseline } from './components/css-baseline.theme';
import { MuiButton } from './components/button.theme';
import { MuiFab } from './components/fab.theme';
import { MuiInputBase } from './components/input-base.theme';
import { MuiOutlinedInput } from './components/outlined-input.theme';
import { MuiFormLabel } from './components/form-label.theme';
import { TypographyTheme } from './components/typography.theme';
import { PaletteTheme } from './components/palette.theme';

declare module '@mui/material/styles' {
    interface Theme {
        PiComponents: {
            PiTwoPaneLayout: PiTwoPaneLayoutThemeOptions;
            PiLetterCircle: PiLetterCircleThemeOptions;
        };
    }

    // allow configuration using `createTheme`
    interface ThemeOptions {
        PiComponents?: {
            PiTwoPaneLayout?: PiTwoPaneLayoutThemeOptions;
            PiLetterCircle?: PiLetterCircleThemeOptions;
        };
    }
}

const lightThemeOptions = generateThemeOptions(piLightThemeOptions, 'light');
const darkThemeOptions = generateThemeOptions(piDarkThemeOptions, 'dark');

export const lightTheme = createTheme(lightThemeOptions);
export const darkTheme = createTheme(darkThemeOptions);

export default lightTheme;

function generateThemeOptions(
    piThemeOptions: ThemeOptions,
    themeType: 'dark' | 'light',
) {
    return {
        ...piThemeOptions,
        // Overwrite the material UI 5 breakpoints
        breakpoints: {
            values: {
                xs: 0,
                sm: 600,
                md: 960,
                lg: 1280,
                xl: 1920,
            },
        },
        components: {
            ...piThemeOptions.components,
            MuiCssBaseline: MuiCssBaseline(piThemeOptions),
            MuiButton: MuiButton(piThemeOptions),
            MuiFab: MuiFab(piThemeOptions),
            MuiInputBase: MuiInputBase(piThemeOptions),
            MuiOutlinedInput: MuiOutlinedInput(piThemeOptions),
            MuiFormLabel: MuiFormLabel(piThemeOptions),
        },
        typography: TypographyTheme(piThemeOptions),
        palette: PaletteTheme(piThemeOptions, themeType),
    };
}
