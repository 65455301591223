import { ThemeOptions } from '@mui/material';
import { breakpoints } from '@sensei/pi-component/dist/theme/breakpoints';

export const TypographyTheme = (theme: ThemeOptions) => {
    return {
        ...theme.typography,
        body1: {
            letterSpacing: 'normal',
            fontSize: '16px',
            [breakpoints.up('lg')]: {
                letterSpacing: 'normal',
                lineHeight: 'normal',
            },
        },
        body2: {
            letterSpacing: 'normal',
            [breakpoints.up('lg')]: {
                letterSpacing: 'normal',
                lineHeight: 'normal',
            },
        },
    };
};
