import { SimplePaletteColorOptions, ThemeOptions } from '@mui/material';
import { colors } from '../colors';

export const MuiFab = (theme: ThemeOptions) => {
    return {
        styleOverrides: {
            root: {
                backgroundColor: 'transparent',
                boxShadow: 'none',
                border: `1px solid ${
                    (theme.palette?.primary as SimplePaletteColorOptions)
                        .main || colors.TRANSPARENT_BLACK
                }`,
            },
            primary: {
                backgroundColor: 'transparent',
                border: `1px solid ${
                    (theme.palette?.secondary as SimplePaletteColorOptions)
                        .main || colors.TRANSPARENT_BLACK
                }`,
            },
            secondary: {
                '&:hover': {
                    backgroundColor: colors.DISABLED_GREY,
                },
            },
        },
    };
};
