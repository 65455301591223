import { typography } from '@sensei/pi-component/dist/theme/typography';

import MinionPro from './MinionPro-Regular.woff';
import MinionPro2 from './MinionPro-Regular.woff2';

import MinionProIt from './MinionPro-It.woff';
import MinionProIt2 from './MinionPro-It.woff2';

import MinionProSemiboldIt from './MinionProSemiboldIt.woff';
import MinionProSemiboldIt2 from './MinionProSemiboldIt.woff2';

import TheinhardtLight from './TheinhardtLight.woff';
import TheinhardtLight2 from './TheinhardtLight.woff2';

import TheinhardtLightItalic from './TheinhardtLightItalic.woff';
import TheinhardtLightItalic2 from './TheinhardtLightItalic.woff2';

import TheinhardtRegular from './TheinhardtRegular.woff';
import TheinhardtRegular2 from './TheinhardtRegular.woff2';

import TheinhardtMedium from './TheinhardtMedium.woff';
import TheinhardtMedium2 from './TheinhardtMedium.woff2';

export const minion = {
    fontFamily: 'Minion Pro',
    fontWeight: typography.fontWeightRegular,
    fontStretch: 'normal',
    fontStyle: 'normal',
    src: `
    url(${MinionPro}) format('woff'),
    url(${MinionPro2}) format('woff2')
  `,
};

export const minionIt = {
    fontFamily: 'Minion Pro',
    fontWeight: typography.fontWeightRegular,
    fontStretch: 'normal',
    fontStyle: 'italic',
    src: `
    url(${MinionProIt}) format('woff'),
    url(${MinionProIt2}) format('woff2')
  `,
};

export const minionSemiBold = {
    fontFamily: 'Minion Semi-bold Pro',
    fontWeight: typography.fontWeightBold,
    fontStretch: 'normal',
    fontStyle: 'italic',
    src: `
    url(${MinionProSemiboldIt}) format('woff'),
    url(${MinionProSemiboldIt2}) format('woff2')
  `,
};

export const theinhardtLight = {
    fontFamily: 'Theinhardt',
    fontWeight: typography.fontWeightLight,
    fontStretch: 'normal',
    fontStyle: 'normal',
    src: `
    url(${TheinhardtLight}) format('woff'),
    url(${TheinhardtLight2}) format('woff2')
  `,
};

export const theinhardtLightItalic = {
    fontFamily: 'Theinhardt',
    fontWeight: typography.fontWeightLight,
    fontStretch: 'normal',
    fontStyle: 'italic',
    src: `
    url(${TheinhardtLightItalic}) format('woff'),
    url(${TheinhardtLightItalic2}) format('woff2')
  `,
};

export const theinhardtRegular = {
    fontFamily: 'Theinhardt',
    fontWeight: typography.fontWeightRegular,
    fontStretch: 'normal',
    fontStyle: 'normal',
    src: `
    url(${TheinhardtRegular}) format('woff'),
    url(${TheinhardtRegular2}) format('woff2')
  `,
};

export const theinhardtMedium = {
    fontFamily: 'Theinhardt',
    fontWeight: typography.fontWeightMedium,
    fontStretch: 'normal',
    fontStyle: 'normal',
    src: `
    url(${TheinhardtMedium}) format('woff'),
    url(${TheinhardtMedium2}) format('woff2')
  `,
};
