import { ThemeOptions } from '@mui/material';

export const MuiOutlinedInput = (theme: ThemeOptions) => {
    return {
        styleOverrides: {
            root: {
                borderRadius: '6px',
                letterSpacing: 'normal',
            },
        },
    };
};
